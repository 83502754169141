import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SlpProjectData } from 'src/app/models';

@Component({
  selector: 'app-subscribed-slp',
  templateUrl: './subscribed-slp.component.html',
  styleUrls: ['./subscribed-slp.component.scss']
})
export class SubscribedSlpComponent {
  @Input() subscribedSlpData: SlpProjectData[] = []
  @Input() isLoading = true;

  constructor(
    private router: Router
  ){}

  navigateToPage(selectedSlpData: SlpProjectData) {
    this.router.navigate(['information'], { state: { savedSlpFormData: selectedSlpData } });
  }
}
