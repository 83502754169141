export class Constants {
    static readonly LOCAL_STORAGE_KEYS = {
        LOGGED_IN_USER_ROLES : 'LoggedInUserRoles'
    }
    static readonly DEFAULT_PAGE_SIZE = 200;
    static readonly YES_NO_FLAG = {
        YES : {
            key : 'Yes',
            value: 1
        },
        NO : {
            key : 'No',
            value: 0
        }
    };
    static readonly STRING_COUNTING = ['One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten', 'Eleven', 'Twelve'];
    static readonly AVAILABLE_OPERATIONS = ['INSERT', 'UPDATE'];
    static readonly SOURCE_PREFIX = 'FIELD_';

    static readonly ONMASSUPDATE='ONMASSUPDATE';

    static readonly SEARCHEQREFRESH='SEARCHEQREFRESH';
    static readonly MATERIALMASTERREFRESH='MATERIALMASTERREFRESH';
    static readonly SERVICEPARTNERREFRESH='SERVICEPARTNERREFRESH';

    static readonly RELATIONSHIPEQREFRESH='RELATIONSHIPEQREFRESH';
    static readonly SEARCHBULKREFRESH='SEARCHBULKREFRESH';
    static readonly RELOADCOLUMNPICKER='RELOADCOLUMNPICKER';

    static readonly ROLES = {
        AT: "AT",
        CT: "CT",
        MR: "MR",
        XP: "XP",
        DNA: "DNA",
        DEVELOPER:'DEVELOPER',
        PROJECTMANAGER:'PROJECTMANAGER'
    }; 

    static readonly YES='Yes';
    static readonly NO='No';
    static readonly CLOSE='Close';

    static readonly SEARCH_URL='/home/search';
    static readonly SEARCH_URL_DETAIL='/home/search/details';

    static readonly MATERIAL_MASTER_URL='/home/materialmaster';
    static readonly MATERIAL_MASTER_URL_DETAIL='/home/materialmaster/details';

    static readonly SERVICE_PARTNER_URL='/home/servicepartner'
    static readonly SERVICE_PARTNER_URL_DETAIL='/home/servicepartner/details'

    static readonly ERROR_LOG_URL='/home/errorlog';
    static readonly ERROR_LOG_DETAIL='/home/errorlog/details';

    static readonly INPUT_INTERFACE_URL='/home/input';
    static readonly INPUT_INTERFACE_DETAIL_URL='/home/input/details';
    
    static readonly OUTBOUND_URL='/home/outbound';
    static readonly OUTBOUND_DETAIL_URL='/home/outbound/details';

    static readonly FUNCTIONAL_LOC_URL='/home/functionlocation';
    static readonly FUNCTIONAL_LOC_DETAIL_URL='/home/functionlocation/details';

    static readonly COUNTRY_URL='/home/country';
    static readonly COUNTRY_DETAIL_URL='/home/country/details';

    static readonly MONITORING_URL='/home/monitoring';


    static readonly LAYOUT = {
        RELATIONSHIP_TAB : 'REL1',
        CONFIGURATION_ITEMS : 'CI_1',
        SERVICE_PARTNER : 'SP_1',
        ERROR_LOG : 'EL_1'
    }
}
