<sh-grid>
    <sh-card label="All SLPs" columns="12">
        <sh-spinner label='Loading All SLPs' *ngIf="isLoading"></sh-spinner>
        <sh-grid style="overflow-y: scroll; padding: 5px 0px;" *ngIf="!isLoading">
            <div *ngFor="let slp of allSlpData" columns="3" class="slpDataCardStyle">
                <app-slp-card [slpData]="slp" [flag]="slp['SUBSCRIBED'] === 'Subscribe' ? 'Unsubscribe' : 'Subscribe' "
                    (updateSlptData)="handlingSubscribed($event)"
                    (projectViewtData)="onViewAllSlpCardClick($event)"
                    (updateSlptDataAfterDelete)="handlingUpdateAllSlpProjectAfterDelete($event)"></app-slp-card>
            </div>
        </sh-grid>
        <sh-empty-state icon='studies-reject' label='No Slp Found'
            *ngIf="!isLoading && allSlpData.length === 0"></sh-empty-state>
    </sh-card>
</sh-grid>