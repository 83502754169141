<div style="padding-top: 20px;"></div>
<sh-grid fit-content>
    <sh-card columns="3" col-m="12" col-s="12">
        <app-create-slp-project-info-card (dataEmitter)="receiveDataFromChild($event)" [slpDetailsCardData]='slpDetailsCardData' columns="12" col-m="12" col-s="12"
            style="display: flex; height: 100px; background-color: #ebebebb9; padding: 20px;">
        </app-create-slp-project-info-card>

        <sh-text size='super-header' columns="12" col-m="12" col-s="12">Your Functional Group selections</sh-text>

        <app-create-slp-selection-card [selectedFGcard]='selectedFGcard'
            *ngFor="let selectedFGcard of selectedUniqueFGDataArray" (FGCardClickedEvent)="showFGData($event)"
            style="height: 120px; margin-top: 10px; margin-bottom: 20px;">
        </app-create-slp-selection-card>
       
    </sh-card>
    <ng-container *ngIf="displayAll; else singleFG">
        <sh-card columns="9" col-m="12" col-s="12">
            <sh-grid fit-content>
                <sh-text size='super-header' columns="12" col-m="12" col-s="12"
                    style="position: sticky; position: -webkit-sticky; top: 0;">
                    All Spare Parts Table
                </sh-text>
                    <table id="sparePartTable" columns="12" col-m="12" col-s="12" style="width: 95%;">
                        <thead>
                            <tr>
                                <th rowspan="2">FG</th>
                                <th rowspan="2">IVK</th>
                                <th rowspan="4">Material</th>
                                <th rowspan="2">Take Rate Reference%</th>
                                <th rowspan="2">Take Rate Target%</th>
                                <th rowspan="2">Work Ref(h)</th>
                                <th rowspan="2">Work Target (h)</th>
                                <th rowspan="2">Travel Ref(h)</th>
                                <th rowspan="2">Travel Target (h)</th>
                                <th rowspan="2">Price (€)</th>
                                <th rowspan="2">Price Target (€)</th>
                                <th rowspan="2">MTBF Ref</th>
                                <th rowspan="2">MTBF Target</th>
                                <th rowspan="2">Cost Per Part Ref</th>
                                <th rowspan="2">Cost Per Part Tar</th>
                                <th rowspan="2">Influence Ref</th>
                                <th rowspan="2">Influence Tar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let fg of fgInfoArrayAll">
                                <td>
                                   {{ fg.FG }}
                                </td>
                                <td>
                                   {{ fg.IVK }}
                                </td>
                                <td>
                                   {{ fg.MATERIAL }}
                                </td>
                                <td>
                                    {{fg.TAKERATEREFERENCE}}
                                </td>
                                <td>{{ fg.TAKERATETARGET }}</td>
                                <td>{{ fg.WORK }}</td>
                                <td>{{ fg.WORKTARGET }}</td>
                                <td>{{ fg.TRAVEL }}</td>
                                <td>{{ fg.TRAVELTARGET }}</td>
                                <td>{{ fg.PRICE }}</td>
                                <td>{{ fg.PRICETARGET }}</td>
                                <td>{{ fg.MTBF }}</td>
                                <td>{{ fg.MTBFTARGET }}</td>
                                <td>{{ fg.COSTPERPARTREFERENCE }}</td>
                                <td>{{ fg.COSTPERPARTTARGET }}</td>
                                <td>{{ fg.INFLUENCE }}</td>
                                <td>{{ fg.INFLUENCETARGET }}</td>
                            </tr>
                        </tbody>
                    </table>
            </sh-grid>
        </sh-card>
    </ng-container>
    <ng-template #singleFG>
        <sh-card columns="9" col-m="12" col-s="12">
            <sh-spinner label='Loading' *ngIf="FGTableSpinner === true && selectedFGPartsCardsId !== -1"></sh-spinner>
            <sh-text *ngIf="selectedFGPartsCardsId === -1" size='super-header'
                style="color: #EC6602; text-align: center;">Please Select the Funcion Group to display Spare Parts
                Data</sh-text>
                <sh-grid fit-content *ngIf="FGTableSpinner === false && selectedFGPartsCardsId !== -1">
                    <sh-text size='super-header' columns="12" col-m="12" col-s="12"
                        style="position: sticky; position: -webkit-sticky; top: 0;">
                        Spare Part Table
                    </sh-text>
                
                    <form [formGroup]="fgForm" (ngSubmit)="ReferencePageOnSubmit()">
                        <table id="sparePartTable" columns="12" col-m="12" col-s="12" style="width: 95%;">
                            <thead>
                                <tr>
                                    <th rowspan="2">FG</th>
                                    <th rowspan="2">IVK</th>
                                    <th rowspan="4">Material</th>
                                    <th rowspan="2">Take Rate Reference%</th>
                                    <th rowspan="2">Take Rate Target%</th>
                                    <th rowspan="2">Work Ref(h)</th>
                                    <th rowspan="2">Work Target (h)</th>
                                    <th rowspan="2">Travel Ref(h)</th>
                                    <th rowspan="2">Travel Target (h)</th>
                                    <th rowspan="2">Price (€)</th>
                                    <th rowspan="2">Price Target (€)</th>
                                    <th rowspan="2">MTBF Ref</th>
                                    <th rowspan="2">MTBF Target</th>
                                    <th rowspan="2">Cost Per System Ref</th>
                                    <th rowspan="2">Cost Per System Tar</th>
                                    <th rowspan="2">Influence Ref</th>
                                    <th rowspan="2">Influence Tar</th>
                                </tr>
                            </thead>
                            <tbody formArrayName="FGInfoArray">
                                <tr *ngFor="let fg of FGInfoArray.controls; let i = index" [formGroupName]="i">
                                    <td>
                                        <ng-container *ngIf="fg.value.isNew; else nonEditableFG1">
                                            <input type="text" formControlName="FG" class="small-input">
                                        </ng-container>
                                        <ng-template #nonEditableFG1>{{ fg.value.FG }}</ng-template>
                                    </td>
                                    <td>
                                        <ng-container *ngIf="fg.value.isNew; else nonEditableFG2">
                                            <input type="text" formControlName="IVK" class="small-input">
                                        </ng-container>
                                        <ng-template #nonEditableFG2>{{ fg.value.IVK }}</ng-template>
                                    </td>
                                    <td>
                                        <ng-container *ngIf="fg.value.isNew; else nonEditableFG3">
                                            <input type="text" formControlName="MATERIAL" class="small-input">
                                        </ng-container>
                                        <ng-template #nonEditableFG3>{{ fg.value.MATERIAL }}</ng-template>
                                    </td>
                                    <td>
                                        <input type="number" formControlName="TAKERATEREFERENCE" class="small-input" 
                                            min="0" max="300" step="0.01" 
                                            pattern="^[0-9]{1,3}(\.\d{1,2})?$" 
                                            title="Enter a number between 0 and 300, up to two decimal places" 
                                            (input)="validateInput($event)" 
                                            (keyup)="onTakeRateRefChange(i)">
                                    </td>
                                    <td>
                                        <input type="number" formControlName="TAKERATETARGET" class="small-input" 
                                            min="0" max="300" step="0.01" 
                                            pattern="^[0-9]{1,3}(\.\d{1,2})?$" 
                                            title="Enter a number between 0 and 300, up to two decimal places" 
                                            (input)="validateInput($event)" 
                                            (keyup)="onTakeRateTargetChange(i)">
                                    </td>
                                    <td>{{ fg.value.WORK }}</td>
                                    <td>
                                        <input type="text" formControlName="WORKTARGET" class="small-input"
                                            pattern="^\d+(\.\d{1,5})?$" title="Only numbers and '.' allowed"
                                            (input)="validateInput($event)" (keyup)="onFGInputChange(i)" (change)="FGInputDataUpdated()">
                                    </td>
                                    <td>{{ fg.value.TRAVEL }}</td>
                                    <td>
                                        <input type="text" formControlName="TRAVELTARGET" class="small-input"
                                            pattern="^\d+(\.\d{1,5})?$" title="Only numbers and '.' allowed"
                                            (input)="validateInput($event)" (keyup)="onFGInputChange(i)" (change)="FGInputDataUpdated()">
                                    </td>
                                    <td>{{ fg.value.PRICE }}</td>
                                    <td>
                                        <input type="text" formControlName="PRICETARGET" class="small-input"
                                            pattern="^\d+(\.\d{1,5})?$" title="Only numbers and '.' allowed"
                                            (input)="validateInput($event)" (keyup)="onFGInputChange(i)" (change)="FGInputDataUpdated()">
                                    </td>
                                    <td>{{ fg.value.MTBF }}</td>
                                    <td>
                                        <input type="text" formControlName="MTBFTARGET" class="small-input"
                                            pattern="^\d+(\.\d{1,5})?$" title="Only numbers and '.' allowed" (keyup)="onFGInputChange(i)"
                                            (input)="validateInput($event)">
                                    </td>
                                    <td>{{ fg.value.COSTPERPARTREFERENCE }}</td>
                                    <td>
                                        <input type="text" formControlName="COSTPERPARTTARGET" readonly="readonly"
                                            class="small-input">
                                    </td>
                                    <td>{{ fg.value.INFLUENCE }}</td>
                                    <td>
                                        <input type="text" formControlName="INFLUENCETARGET" readonly="readonly"
                                            class="small-input">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="button-container">
                            <sh-button label="Submit" (click)="ReferencePageOnSubmit()"></sh-button>
                            <sh-button *ngIf="visibilebutton" label="Add Parts" (click)="onDropdownChange()"></sh-button>
                        </div>
                    </form>
                </sh-grid>
                
        </sh-card>
    </ng-template>
   
  
    <div *ngIf="showCreateSlpSuccessDialog" class="custom-success-dialog">
        <div class="custom-dialog-content">
            <h1>Success</h1>
            <p>Record(s) have been successfully added!</p>
            <button class="dark-orange-button" (click)="closeCreateSlpDialog()">Ok</button>
        </div>
    </div>
    <div *ngIf="showerror" class="custom-success-dialog">
        <div class="custom-dialog-content">
            <h2>The Reference System values are not saved. </h2>
            <h3>Do you still want to continue ? </h3>
            <button class="dark-orange-button" (click)="cancelToggle()" style="margin-right:20px;">Cancel</button>
            <button class="dark-orange-button" (click)="confirmToggle()">Confirm</button>
        </div>
    </div>
</sh-grid>