import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Constants } from 'src/app/constants';
import { SlpProjectData } from 'src/app/models';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-slp-card',
  templateUrl: './slp-card.component.html',
  styleUrls: ['./slp-card.component.scss']
})

export class SLPCardComponent implements OnInit {
  constructor(private roleService: RoleService) { }

  @Input() slpData: SlpProjectData;
  @Input() flag: string;
  @Output() updateSlptData = new EventEmitter<number>();
  @Output() updateSlptDataAfterDelete = new EventEmitter<number>();
  @Output() projectViewtData = new EventEmitter<SlpProjectData>();

  date: string;
  showDelete: boolean;
  showConfirmationDlg: boolean;
  showCreateSlpSuccessDialog: boolean;

  ngOnInit() {
    this.date = this.formatDate(this.slpData.DUEDATE);
    
    this.showDelete = this.roleService.isDeveloperOrProjectManager() ? true :  false;
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  onSubscribe() {
    this.updateSlptData.emit(this.slpData.SLPID);
  }

  onProjectView() {
    this.projectViewtData.emit(this.slpData);
  }

  deleteItem() {
    this.showConfirmationDlg = true;
  }

  cancelToggle() {
    this.showConfirmationDlg = false;
  }

  confirmToggle() {
    this.updateSlptDataAfterDelete.emit(this.slpData.SLPID);
    this.showConfirmationDlg = false;
    this.showCreateSlpSuccessDialog = true;
  }

  closeCreateSlpDialog() {
    this.showCreateSlpSuccessDialog = false;
  }

}
