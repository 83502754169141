import { Component, OnInit, ViewChild, Input, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';
import { formatDate } from '@angular/common';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { SHDropdown } from '@shui/core/sh-dropdown';

import { AuthService } from 'src/app/services/auth.service';
import { RoleService } from 'src/app/services/role.service';
import { SqlServiceService } from 'src/app/core/sql-service.service';
import { BaseSystemData, TargetSystemDataArray, BaseSystemDataCard, SlpProjectData, SlpCostTableData, YearListData, NotificationCategory, SLpNullTypeCostTable } from 'src/app/models';

@Component({
  selector: 'app-slp-project-data-form',
  templateUrl: './slp-project-data-form.component.html',
  styleUrls: ['./slp-project-data-form.component.scss']
})

export class SlpProjectDataFormComponent implements OnInit {

  @ViewChild('baseSystemSearchInputRef') baseSystemSearchInputElement: ElementRef;
  @ViewChild('yearsDropdownRef') yearsDropdown: ElementRef;
  @ViewChild('notificationCategoriesDropdownRef') notificationCategoriesDropdown: ElementRef;
  @Output() isFormInvalid = new EventEmitter<boolean>();
  @Output() hourlyRateValue = new EventEmitter<number>();
  @Output() slpFormFieldsForStoreProcedurevalue = new EventEmitter<{ field: string, value: string }>();
  @Input() savedSlpFormData: SlpProjectData;
  @Input() savedTargetSystemFormData: TargetSystemDataArray;
  @Input() savedSlpCostTableData: SlpCostTableData;
  @Input() selectedBaseSystemDetailsDataArray: BaseSystemDataCard[] = [];
  @Input() savedNotificationCategoriesArray: NotificationCategory[] = [];
  @Input() responseSlpCostTableData: SLpNullTypeCostTable;
  @Input() savedYearList: YearListData[] = [];

  constructor(
    private sqlServiceService: SqlServiceService,
    public authService: AuthService,
    private formBuilder: FormBuilder,
    private roleService: RoleService
  ) { }

  businessLineArray: string[] = [];
  slpStatusArray: string[] = []
  slpbusinessline: string = '';
  enablenext: boolean = true;
  isNecessary: boolean = true
  isMonitoring: boolean = true
  loading: boolean = false;
  slpStatus: string = 'In Work';
  yearsList: YearListData[] = [];
  baseSystemDataArray: any[] = [];
  selectedBaseSystems: BaseSystemData[] = [];
  filteredBaseSystemDataArray: BaseSystemData[] = [];
  isBaseSystemSearchDropDownOpen: boolean = false;
  selectedBaseSystemsArray: BaseSystemDataCard[] = [];
  notificationCategoriesArray: NotificationCategory[] = [];
  isAllStNotificationCategorySelected: boolean = false;
  notificationSelectedCount: number = 0
  selectedCardId: number = -1;
  lastSavedSlpId: number;

  SlpProjectDataForm: FormGroup;
  TargetSystemDataForm: FormGroup;
  CostTableForm: FormGroup

  private addTargetSystemDataForm(): FormGroup {
    return this.formBuilder.group({
      SLPSYSTEMNAME: new FormControl(null),
      SLPSYSTEMIVK: new FormControl(null)
    });
  }

  ngOnInit(): void {
    this.initializeForms();
    this.loadInitialData();
    this.restoreSavedData();
    this.setupTargetSystemForm();

    // generate list of years
    if (this.savedYearList.length === 0) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      this.setListOfYears(currentYear);
    }

    this.setCostTaleFormValidation()

    this.updateSlpDataFormHourRate();
    this.updateSlpFormValuesUsedForStoreProcedureUpdated();
    this.checkSlpDataFormStatus();
  }

  ngAfterViewInit() {
    this.baseSystemSearchInputElement.nativeElement.value = "";
  }

  initializeForms(): void {
    this.SlpProjectDataForm = this.formBuilder.group({
      BUISNESSLINE: new FormControl(this.slpbusinessline, Validators.required),
      SLPNAME: new FormControl(null, Validators.required),
      CSPROJECTMANAGER: new FormControl(null, Validators.required),
      CSEXPERT: new FormControl(null, Validators.required),
      CBLEXPERT: new FormControl(''),
      STATUS: new FormControl(this.slpStatus, Validators.required),
      DUEDATE: new FormControl(formatDate(new Date(), "YYY-MM-dd", "en")),
      SLPNECESSARY: new FormControl('true'),
      SLPNECESSARYREASONDATA: new FormControl(null),
      ISMONITORINGNECESSARY: new FormControl('true'),
      MONITORINGREASONDATA: new FormControl(null),
      PROJECTDESCRIPTION: new FormControl(null),
      BASESYSTEMS: new FormControl(null, Validators.required),
      BASESYSTEMSNAMES: new FormControl(null),
      YEARS: new FormControl(null, Validators.required),
      NOTIFICATIONCATEGORY: new FormControl(null, Validators.required),
      SLPHOURRATE: new FormControl(null, Validators.required),
    });

    this.TargetSystemDataForm = this.formBuilder.group({
      TARGETSYSTEMDATARRAY: this.formBuilder.array([this.addTargetSystemDataForm()]),
    });

    this.CostTableForm = this.formBuilder.group({
      IBRelease: new FormControl(null),
      IBYear1: new FormControl(null),
      IBYear2: new FormControl(null),
      IBYear3: new FormControl(null),
      IBYear4: new FormControl(null),
      IBYear5: new FormControl(null),

      HourlyRateRelease: new FormControl(null, Validators.required),
      HourlyRateYear1: new FormControl(null),
      HourlyRateYear2: new FormControl(null),
      HourlyRateYear3: new FormControl(null),
      HourlyRateYear4: new FormControl(null),
      HourlyRateYear5: new FormControl(null),

      PartCostIncreaseRelease: new FormControl(null),
      PartCostIncreaseYear1: new FormControl(null),
      PartCostIncreaseYear2: new FormControl(null),
      PartCostIncreaseYear3: new FormControl(null),
      PartCostIncreaseYear4: new FormControl(null),
      PartCostIncreaseYear5: new FormControl(null),

      ProductivityMeasureRelease: new FormControl(null),
      ProductivityMeasureYear1: new FormControl(null),
      ProductivityMeasureYear2: new FormControl(null),
      ProductivityMeasureYear3: new FormControl(null),
      ProductivityMeasureYear4: new FormControl(null),
      ProductivityMeasureYear5: new FormControl(null),

    });
  }

  private loadInitialData(): void {
    this.slpStatusArray = ["In Work", "Released", "Completed"];
    this.businessLineArray = this.roleService.getBusinessLines();

    if (this.savedNotificationCategoriesArray.length === 0) {
      this.loadNotificationCategories();
    }

    this.sqlServiceService.getNewSystems().subscribe((response: any) => {
      this.baseSystemDataArray = response;
      this.filteredBaseSystemDataArray = response;
    });
  }

  private loadNotificationCategories(): void {
    const selectedNotificationCategoriesArray = this.setNotificationCategories();

    this.sqlServiceService.getNotificationCategories().subscribe((response: any) => {
      this.notificationCategoriesArray = response
        .map((category: any) => ({
          ...category,
          isSelected: selectedNotificationCategoriesArray.includes(category.notiCategoryId)
        }))
        .sort((a: NotificationCategory, b: NotificationCategory) => parseInt(a.notiCategoryId) - parseInt(b.notiCategoryId));
    });
  }

  private restoreSavedData(): void {
    if (this.savedSlpFormData) {
      this.SlpProjectDataForm.patchValue(this.savedSlpFormData);
      this.slpbusinessline = this.savedSlpFormData['BUISNESSLINE'];
      this.slpStatus = this.savedSlpFormData['STATUS'];
      this.isNecessary = this.savedSlpFormData['SLPNECESSARY'] === 'true';
      this.isMonitoring = this.savedSlpFormData['ISMONITORINGNECESSARY'] === 'true';
      this.setSelectedBaseSystemDetailsDataArray();
      this.notificationCategoriesArray = this.savedNotificationCategoriesArray;
      this.yearsList = this.savedYearList;
    }

    if (this.savedSlpCostTableData) {
      this.CostTableForm.patchValue(this.savedSlpCostTableData);
    }

    if (this.responseSlpCostTableData && !this.savedSlpCostTableData) {
      this.CostTableForm.patchValue(this.responseSlpCostTableData);
    }

    this.isFormInvalid.emit(this.SlpProjectDataForm.invalid);

  }

  private setupTargetSystemForm(): void {
    if (this.savedTargetSystemFormData) {
      this.savedTargetSystemFormData.TARGETSYSTEMDATARRAY.forEach((targetData) => {
        if (targetData.SLPSYSTEMNAME && targetData.SLPSYSTEMIVK) {
          const savedTargetDataForm = this.formBuilder.group({
            SLPSYSTEMNAME: targetData.SLPSYSTEMNAME,
            SLPSYSTEMIVK: targetData.SLPSYSTEMIVK
          });
          this.getTargetFieldsFormInfo.push(savedTargetDataForm);
        }
      });
      this.removeTargetField(0);
    }
  }

  setListOfYears(inputYear: number): void {
    const startYear = this.getStartYear(inputYear);
    this.initializeYearsList(startYear);
    this.selectSavedYears();
    this.updateSlpFormValuesUsedForStoreProcedureUpdated();
  }

  getStartYear(inputYear: number): number {
    const currentYear = new Date().getFullYear();
    return Math.max(inputYear, currentYear);
  }

  initializeYearsList(startYear: number): void {
    this.yearsList = Array.from({ length: 5 }, (_, index) => startYear - index).map(year => ({
      years: year,
      isSelected: false
    }));
  }

  selectSavedYears(): void {
    const savedYears = this.savedSlpFormData?.YEARS?.split(',').sort((a, b) => a.localeCompare(b)) || [];
    savedYears.forEach((year) => {
      const index = this.yearsList.findIndex((item) => item.years === Number(year));
      if (index !== -1) {
        this.onSelectYearCheckboxClick(index);
      }
    });
  }

  setCostTaleFormValidation(): void {
    this.disableCostTaleFormValidationOnInitilisation("IBYear")
    this.disableCostTaleFormValidationOnInitilisation("HourlyRateYear")
    this.disableCostTaleFormValidationOnInitilisation("PartCostIncreaseYear")
    this.disableCostTaleFormValidationOnInitilisation("ProductivityMeasureYear")
  }

   checkSlpDataFormStatus(): void {
    this.SlpProjectDataForm.statusChanges.subscribe(state => {
      this.isFormInvalid.emit(this.SlpProjectDataForm.invalid);
    });
  }

  updateSlpDataFormHourRate(): void {
    this.CostTableForm.get('HourlyRateRelease')?.valueChanges.subscribe((value) => {
      this.SlpProjectDataForm.patchValue({ SLPHOURRATE: value })
      this.hourlyRateValue.emit(Number(value));
    });
  }

  updateSlpFormValuesUsedForStoreProcedureUpdated(): void {
    const savedFields = [
      { name: 'BASESYSTEMS' },
      { name: 'YEARS' },
      { name: 'NOTIFICATIONCATEGORY' },
    ];

    savedFields.forEach(({ name }) => {
      this.SlpProjectDataForm.get(name)?.valueChanges.subscribe((newValue) => {
        this.slpFormFieldsForStoreProcedurevalue.emit({ field: name, value: newValue });
      });
    });

  }

  updateIsNecessary(): void {
    setTimeout(() => {
      this.isNecessary = !this.isNecessary
      const isNecessaryFormValue = this.isNecessary ? 'true' : false
      this.SlpProjectDataForm.patchValue({ SLPNECESSARY: isNecessaryFormValue })
      this.setConditionalValidationForNessaryAndMonitoring('SLPNECESSARYREASONDATA', this.isNecessary)
    },0)
  }

  updateIsMonitoring(): void {
    setTimeout(() => {
      this.isMonitoring = !this.isMonitoring
      this.SlpProjectDataForm.patchValue({ ISMONITORINGNECESSARY: this.isMonitoring ? 'true' : 'false' })
      this.setConditionalValidationForNessaryAndMonitoring('MONITORINGREASONDATA', this.isMonitoring)
    },0)
  }

  setConditionalValidationForNessaryAndMonitoring(controlName: string, isSetFalse: boolean): void {
    !isSetFalse ? this.SlpProjectDataForm.get(controlName)?.setValidators(Validators.required) : this.SlpProjectDataForm.get(controlName)?.clearValidators()
    this.SlpProjectDataForm.get(controlName)?.updateValueAndValidity();
  }

  updateSelectedBusinessLine(selectedBusinessLineValue: string) {
    this.slpbusinessline = selectedBusinessLineValue;
    this.SlpProjectDataForm.patchValue({ BUISNESSLINE: selectedBusinessLineValue })
  }

  async dateChange(e: any) {
    const inputYearString = e.target.value
    if (this.SlpProjectDataForm.controls['DUEDATE'].value !== inputYearString) {
      this.SlpProjectDataForm.patchValue({
        DUEDATE: inputYearString
      });
      const inputYear: number = Number(inputYearString.split('-')[0])
      const dropdown = document.getElementById('years-dropdown') as SHDropdown;
      dropdown?.unselectActiveItems();
      if (this.yearsList.length > 0) {
        this.yearsList = []
      }
      this.setListOfYears(inputYear);
    }
  }

  updateStatus(selectedStatusValue: string) {
    this.slpStatus = selectedStatusValue;
    this.SlpProjectDataForm.patchValue({ STATUS: selectedStatusValue })
  }

  onSelectYearCheckboxClick(index: number) {
    setTimeout(() => {
      this.yearsList[index]["isSelected"] = !this.yearsList[index]["isSelected"]
      if (this.yearsDropdown.nativeElement.value === '') {
        this.SlpProjectDataForm.get('YEARS')?.setErrors({ invalid: true })
      }
    });
  }

  onSelectNotificationCategoryClick(index: number) {
    setTimeout(() => {
      this.notificationSelectedCount = this.notificationCategoriesArray[index]['isSelected'] ? this.notificationSelectedCount - 1 : this.notificationSelectedCount + 1
      this.isAllStNotificationCategorySelected = this.notificationSelectedCount === this.notificationCategoriesArray.length ? true : false
      this.notificationCategoriesArray[index]['isSelected'] = !this.notificationCategoriesArray[index]["isSelected"]
      if (this.notificationCategoriesDropdown.nativeElement.value === '') {
        this.SlpProjectDataForm.get('NOTIFICATIONCATEGORY')?.setErrors({ invalid: true })
      }
    })
  }

  onSelectAllNotificationCategoryClick() {
    setTimeout(() => {
      this.isAllStNotificationCategorySelected = !this.isAllStNotificationCategorySelected

      this.notificationCategoriesArray = this.notificationCategoriesArray.map((categories: NotificationCategory) => ({
        ...categories,
        isSelected: this.isAllStNotificationCategorySelected
      })
      )
      this.notificationSelectedCount = this.isAllStNotificationCategorySelected ? this.notificationCategoriesArray.length : 0
    })
  }

  setSelectedBaseSystemDetailsDataArray() {
    const baseSystemsArray: BaseSystemDataCard[] = this.selectedBaseSystemDetailsDataArray
    if (this.savedSlpFormData.BASESYSTEMS && this.selectedBaseSystemsArray.length === 0) {
      const baseSystemMatIdArray = this.savedSlpFormData.BASESYSTEMS.split(',')
      const baseSystemMatTextArray = this.savedSlpFormData.BASESYSTEMSNAMES.split(',')
      baseSystemMatIdArray.forEach((baseSystemId, index) => {
        let isBaseSystemPresent: boolean = false
        if (this.selectedBaseSystemDetailsDataArray.some(baseSystem => baseSystem.MATID === baseSystemId)) {
          isBaseSystemPresent = true
        }

        if (!isBaseSystemPresent) {
          baseSystemsArray.push({ MATID: baseSystemId, MATTEXT: baseSystemMatTextArray[index], id: index, isSelected: false, costPerSystem: 0 })
        }
      })
    }
    this.selectedBaseSystemsArray = baseSystemsArray
    const baseSystemMatIdtring = this.selectedBaseSystemsArray.map(selectedBaseSystem => selectedBaseSystem.MATID).join()
    this.SlpProjectDataForm.patchValue({ BASESYSTEMS: baseSystemMatIdtring })
  }

  setNotificationCategories(): string[] {
    const notificationCategoryString = this.savedSlpFormData?.["NOTIFICATIONCATEGORY"] ?? '';
    if (!notificationCategoryString) {
      return [];
    }
    return notificationCategoryString
      .split(',')
      .map(category => category.split('-')[0].trim());
  }

  // Functions to handel search functionality for base system
  filterBaseSystemSearchResults(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredBaseSystemDataArray = this.baseSystemDataArray.filter(slp =>
      slp.MATID.toLowerCase().includes(filterValue) || slp.MATTEXT.toLowerCase().includes(filterValue)
    );
  }

  showDropDown() {
    this.isBaseSystemSearchDropDownOpen = true
  }

  hideDropDown() {
    this.isBaseSystemSearchDropDownOpen = false
    this.baseSystemSearchInputElement.nativeElement.value = "";
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    if (this.isBaseSystemSearchDropDownOpen && !this.baseSystemSearchInputElement.nativeElement.contains(event.target)) {
      this.hideDropDown();
    }
  }

  onBaseSystemSelection(slp: BaseSystemData) {
    const selectedBaseSystem: BaseSystemDataCard = {
      MATID: slp.MATID,
      MATTEXT: slp.MATTEXT,
      id: this.selectedBaseSystemsArray.length,
      isSelected: false,
      costPerSystem: 0
    };
    let isBaseSystemPresent: boolean = false

    if (this.selectedBaseSystemsArray.some(baseSystem => baseSystem.MATID === selectedBaseSystem.MATID)) {
      isBaseSystemPresent = true
    }
    if (!isBaseSystemPresent) {
      this.selectedBaseSystemsArray.push(selectedBaseSystem);
      const baseSystemMatIdtring = this.selectedBaseSystemsArray.map(baseSystem => baseSystem.MATID).join()
      this.SlpProjectDataForm.patchValue({ BASESYSTEMS: baseSystemMatIdtring })
      this.updateSlpFormValuesUsedForStoreProcedureUpdated()
    }
  }

  removeSelectedBaseSystem(baseSystem: BaseSystemData) {
    this.selectedBaseSystemsArray = this.selectedBaseSystemsArray.filter(item => item.MATID !== baseSystem.MATID);
    const baseSystemMatIdtring = this.selectedBaseSystemsArray.map(selectedBaseSystem => selectedBaseSystem.MATID).join()
    this.SlpProjectDataForm.patchValue({ BASESYSTEMS: baseSystemMatIdtring })
    this.updateSlpFormValuesUsedForStoreProcedureUpdated()
    if (this.selectedBaseSystemsArray.length === 0) {
      this.selectedCardId = -1
      this.SlpProjectDataForm.get('BASESYSTEMS')?.setErrors({ invalid: true })
    }
  }

  // Handling Form Array for Target System
  get getTargetFieldsFormInfo(): FormArray {
    return this.TargetSystemDataForm.get('TARGETSYSTEMDATARRAY') as FormArray
  }
  addNewTargetField() {
    this.getTargetFieldsFormInfo.push(this.addTargetSystemDataForm());
  }
  removeTargetField(index: number) {
    if (this.getTargetFieldsFormInfo.length > 1) {
      this.getTargetFieldsFormInfo.removeAt(index);
    }
  }

  disableCostTaleFormValidationOnInitilisation(control: string): void {
    for (let year = 2; year <= 5; year++) {
      const presentControl = control + year
      const previousControl = control + (year - 1)
      const formFieldValue = this.CostTableForm.get(presentControl)?.value
      const previousFormFieldValue = this.CostTableForm.get(previousControl)?.value
      if ((formFieldValue === null && previousFormFieldValue === null) || (Number.isNaN(formFieldValue) && Number.isNaN(previousFormFieldValue))) {
        this.CostTableForm.get(presentControl)?.disable()
        this.CostTableForm.get(presentControl)?.reset()
      }
    }
  }

  handelCostTableFormValidation(presentControl: string, indexString: string): void {
    const index = Number(indexString)
    if (this.CostTableForm.get(presentControl + index)?.value !== '') {
      const nextControl = `${presentControl}${index + 1}`
      this.CostTableForm.get(nextControl)?.enable()
    } else {
      for (let year = index + 1; year <= 5; year++) {
        const nextControl = presentControl + year
        if (this.CostTableForm.get(nextControl)?.value !== '') {
          this.CostTableForm.get(nextControl)?.reset()
        }
        this.CostTableForm.get(nextControl)?.disable()
      }
    }
  }

  async onSlpProjectDataFormSubmit(isSLPID: number) {
    const formInputProjectData: SlpProjectData = this.SlpProjectDataForm.getRawValue();
    const formInputTargetData: TargetSystemDataArray = this.TargetSystemDataForm.getRawValue();
    const costTableDataForm: SlpCostTableData = this.CostTableForm.getRawValue();

    formInputProjectData.NOTIFICATIONCATEGORY = this.notificationCategoriesDropdown.nativeElement.value || '';
    formInputProjectData.YEARS = this.yearsDropdown.nativeElement.value;
    const stringOfSystems = formInputTargetData.TARGETSYSTEMDATARRAY.map(inputValue => inputValue.SLPSYSTEMNAME).join();
    const stringOfIVK = formInputTargetData.TARGETSYSTEMDATARRAY.map(inputValue => inputValue.SLPSYSTEMIVK).join();
    const baseSystemMatIdtring = this.selectedBaseSystemsArray.map(selectedBaseSystem => selectedBaseSystem.MATID).join();
    const baseSystemMatTextString = this.selectedBaseSystemsArray.map(selectedBaseSystem => selectedBaseSystem.MATTEXT).join();

    formInputProjectData.BASESYSTEMS = baseSystemMatIdtring;
    formInputProjectData.BASESYSTEMSNAMES = baseSystemMatTextString;
    formInputProjectData.SLPSYSTEMIVK = stringOfIVK;
    formInputProjectData.SLPSYSTEMNAME = stringOfSystems;
    formInputProjectData.STATUS = this.slpStatus;
    formInputProjectData.BUISNESSLINE = this.slpbusinessline;
    formInputProjectData.MODALITY = "CT";
    formInputProjectData.SUBSCRIBED = "Unsubscribe";
    if (isSLPID == 0) {
      formInputProjectData.CREATEDBY = this.authService.getAccount()?.username.toString();
    }
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = currentDate.getDate().toString().padStart(2, '0');
    const formattedDate = year + "-" + month + "-" + day;
    formInputProjectData.CREATEDDATE = formattedDate;
    if (isSLPID != 0) {
      formInputProjectData.UPDATEDDATE = formattedDate;
      formInputProjectData.UPDATEDBY = this.authService.getAccount()?.username.toString();
    } else {
      formInputProjectData.UPDATEDDATE = '';
      formInputProjectData.UPDATEDBY = '';
    }

    // Data from slpCostTable
    formInputProjectData.IB = costTableDataForm.IBRelease;
    formInputProjectData.IBYEARS = `${costTableDataForm.IBYear1},${costTableDataForm.IBYear2},${costTableDataForm.IBYear3},${costTableDataForm.IBYear4},${costTableDataForm.IBYear5}`;
    formInputProjectData.SLPHOURRATE = costTableDataForm.HourlyRateRelease;
    formInputProjectData.SLPHOURRATEYEARS = `${costTableDataForm.HourlyRateYear1},${costTableDataForm.HourlyRateYear2},${costTableDataForm.HourlyRateYear3},${costTableDataForm.HourlyRateYear4},${costTableDataForm.HourlyRateYear5}`;
    formInputProjectData.PARTCOSTINCREASE = costTableDataForm.PartCostIncreaseRelease;
    formInputProjectData.PARTCOSTINCREASEYEARS = `${costTableDataForm.PartCostIncreaseYear1},${costTableDataForm.PartCostIncreaseYear2},${costTableDataForm.PartCostIncreaseYear3},${costTableDataForm.PartCostIncreaseYear4},${costTableDataForm.PartCostIncreaseYear5}`;
    formInputProjectData.PARTCOSTINCREASE = costTableDataForm.PartCostIncreaseRelease;
    formInputProjectData.PRODUCTIVITYMEASURE = costTableDataForm.ProductivityMeasureRelease;
    formInputProjectData.PRODUCTIVITYMEASUREYEARS = `${costTableDataForm.ProductivityMeasureYear1},${costTableDataForm.ProductivityMeasureYear2},${costTableDataForm.ProductivityMeasureYear3},${costTableDataForm.ProductivityMeasureYear4},${costTableDataForm.ProductivityMeasureYear5}`;
    formInputProjectData.ISACTIVE = true;
    this.SlpProjectDataForm.patchValue(formInputProjectData);
    await this.sqlServiceService.addRecord(formInputProjectData, isSLPID);
  }

}