import {environment} from '../../environments/environment';

export const API_BASE_URL: string = environment.apiBaseUrl;

export const API_URLS = {
  // SLP_DATA: `${API_BASE_URL}api/slpMasterDataTable`,
  UPDATE_FG : `${API_BASE_URL}api/updateFG`,
  // DISTINCT_SYSTEMS: `${API_BASE_URL}api/distinctSystems`,
  FG_DATA: `${API_BASE_URL}api/FGData`,
  CHECK_IF_IVK_EXISTS_IN_SERVICE_PARTS: `${API_BASE_URL}api/CheckifIVKexistsinServiceParts`,
  FG_TABLE_IVK_DELETE: `${API_BASE_URL}api/FGTableIVKDelete`,
  SYSTEM_FETCH_URL: `${API_BASE_URL}api/distinctSystemsNew`,
  UPDATE_FG_NAME: `${API_BASE_URL}api/UpdateFGName`,
  BUSINESS_LINE_FETCH: `${API_BASE_URL}api/distinctBusinessLine`,
  FG_TABLE_POST: `${API_BASE_URL}api/FGtablepost`,
  GET_USER_ROLES : `${API_BASE_URL}api/GetUserRoleIDs`,
  GET_SAVED_FGDATA_FOR_RELOAD: `${API_BASE_URL}api/ServicePartsIBaseResultFGData`,
  GET_SAVED_OTHERS_FOR_RELOAD: `${API_BASE_URL}api/ServicePartsIBaseResultOthersData`,
  GET_SAVED_NOPART_FOR_RELOAD: `${API_BASE_URL}api/ServicePartsIBaseResultNoPartData`,
  FETCH_REFERENCE_SYSTEMS_FOR_SLP: `${API_BASE_URL}api/FetchReferenceSystemStoredDataForSLP`,
  SYSTEM_INFO_DATA_FOR_VIEW_SLP: `${API_BASE_URL}api/SystemInformationDataForSLP`,
  FG_TABLE_DELETE: `${API_BASE_URL}api/FGDelete`,
  SLP_PROJECTDATA_DELETE: `${API_BASE_URL}api/DeleteProject`,
  GET_CHECKED_DATA_FOR_SLP: `${API_BASE_URL}api/ConsolidatedServicePartsIBaseResults`,
  CHANGE_STATE_IN_STORED_PROCEDURE: `${API_BASE_URL}api/ChangeStateInStoredProcedure`,
  CHECK_IF_IVK_EXISTS: `${API_BASE_URL}api/CheckIVKExists`,
  UNIQUE_FG_FETCH: `${API_BASE_URL}api/UniqueFGfetch`,
  FG_PARTS_FETCH: `${API_BASE_URL}api/FetchFGParts`,
  CALL_STORED_PROCEDUREFOREXISTINGSLP: `${API_BASE_URL}api/CallStoredProcedure`,
  CALL_STORED_PROCEDUREFORNEWSLP: `${API_BASE_URL}api/CallStoredProcedureForNewSLP`,
  GET_RECENT_SLP_ID: `${API_BASE_URL}api/getRecentSlpProjectId`,
  SERVICE_PARTS_MATERIAL_NAME: `${API_BASE_URL}api/ServicePartsMatNameFetch`,
  MATERIAL_FETCH_URL: `${API_BASE_URL}api/ServicePartsFiltered`,
  BASE_SYSTEM_SERVICE_PARTS_FETCH: `${API_BASE_URL}api/ServicePartsFilteredonMATID`,  //to get service parts base system material id and Selected IVK in the functional groups.
  SERVICE_PARTS_FETCH_2: `${API_BASE_URL}api/ServicePartsFilteredonFG`,
  SERVICE_PARTS_FETCH_OTHERS: `${API_BASE_URL}api/ServicePartsOthers`,
  NOTIFICATION_CATEGORIES_FETECH: `${API_BASE_URL}api/DistinctNotificationCategories`,
  SUM_IB: `${API_BASE_URL}api/FetchSUMIB`,
  USER_POST_URL: `${API_BASE_URL}api/SlpProjectData`,
  SERVICE_PARTS_URL: `${API_BASE_URL}api/ServiceParts`,
  USER_DATA_POST_URL: `${API_BASE_URL}api/slpUserDataTable`,
  USER_DATA_POST_URL_NEW: `${API_BASE_URL}api/SlpUserDataTableNew`,
  SLPS: `${API_BASE_URL}api/slpData`,
  PROJECT_DATA_FETCH : `${API_BASE_URL}api/SlpProjectDataFetch`,
  SLP_USER_DATA: `${API_BASE_URL}api/SlpProjectDataPost`,
  UPDATE_SUBSCRIBED_SLPS: `${API_BASE_URL}api/updateSubscribe`,
  // GET_SUBSCRIBED_SLPS: `${API_BASE_URL}api/SubscribedSystems`,
  GET_SUBSCRIBED_SLPS: `${API_BASE_URL}api/getSubscribedSystemsByCreatedUser`,
  TOGGLE_SUBSCRIBED_SLP: `${API_BASE_URL}api/toggleSubscribedSystems`,
  GET_SUBSCRIBED_SLPS_STATUS: `${API_BASE_URL}api/getSubscribedSlpStatus`,
  // GET_IN_PROGRESS: `${API_BASE_URL}api/inProgressData`,
  // UPDATE_TARGET_VALUES: `${API_BASE_URL}api/updateTarget`,
  // GET_SLP_CHART_STATUS_URL: `${API_BASE_URL}api/SlpChartStatus`,
  UPDATE_USER_INFO: `${API_BASE_URL}api/updateBasicInfo`, //TO-DO: Check if this URL is needed. Couldn't find any dependencies.
  // UPDATE_USER_DATA_INFO: `${API_BASE_URL}api/updateBasicInfoUserData`,
  DISTINCT_FG: `${API_BASE_URL}api/distinctFG`,
  // UPDATE_INVESTMENT_DATA: `${API_BASE_URL}api/updateInvestment`,
};
