import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { JwtAuthGuard } from './shared/guards/JwtAuthGuard';
import { PermissionGuard } from './guards/permission.guard';

import { MonitoringComponent } from './modules/monitoring/monitoring.component';
import { CreateSlpFormComponent } from './modules/planning/create-slp/create-slp-form/create-slp-form.component';
import { FunctionalgroupComponent } from './modules/functionalgroup/functionalgroup.component';
import { AccessDeniedComponent } from './modules/access-denied/access-denied/access-denied.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/login' },
  { path: 'login', loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule) },
  { path: 'callback', pathMatch: 'full', redirectTo: '/home'},
  {
    path: 'home',
    component: MonitoringComponent,
    canActivate: [ environment.isE2E ? JwtAuthGuard : MsalGuard,
      PermissionGuard
    ]
  },
  {
    path: 'information',
    component: CreateSlpFormComponent,
    canActivate: [ PermissionGuard]
  },
  {
    path: 'functionalgroup',
    component: FunctionalgroupComponent,
    canActivate: [ PermissionGuard]
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent,
  },
  { path: '**', pathMatch: 'full', redirectTo: '/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
