<div style="display: flex; width: 250px; flex-direction: column; width: 40%;" (click)="send()">
    <sh-text size="header-1" id="baseSystemCardProject"> Project</sh-text>
    <sh-tooltip label="Project" target="baseSystemCardProject" placement='top' variation='short'></sh-tooltip>
    <sh-text size="header-1" id="baseSystemCardProjectManager">Project Manager</sh-text>
    <sh-tooltip label="Project Manager" target="baseSystemCardProjectManager" placement='top'
        variation='short'></sh-tooltip>
    <sh-text size="header-1" id="baseSystemCardHourRate">Hour Rate</sh-text>
    <sh-tooltip label="Hour Rate" target="baseSystemCardHourRate" placement='top' variation='short'></sh-tooltip>
    <sh-text size="header-1" id="baseSystemCardM300">M300</sh-text>
    <sh-tooltip label="M300" target="baseSystemCardM300" placement='top' variation='short'></sh-tooltip>
</div>
<div style="display: flex; width: 250px; flex-direction: column; width: 60%;">
    <sh-text size="header-2" id="baseSystemCardProjectData">- {{slpDetailsCardData.projectName}}</sh-text>
    <sh-tooltip label="{{slpDetailsCardData.projectName}}" target="baseSystemCardProjectData" placement='top'
        variation='short'></sh-tooltip>
    <sh-text size="header-2" id="baseSystemCardProjectManagerData">- {{slpDetailsCardData.projectManager}}</sh-text>
    <sh-tooltip label="{{slpDetailsCardData.projectManager}}" target="baseSystemCardProjectManagerData" placement='top'
        variation='short'></sh-tooltip>
    <sh-text size="header-2" id="baseSystemCardHourRateData">- {{slpDetailsCardData.hourRate}}</sh-text>
    <sh-tooltip label="{{slpDetailsCardData.hourRate}}" target="baseSystemCardHourRateData" placement='top'
        variation='short'></sh-tooltip>
    <sh-text size="header-2" id="baseSystemCardM300Data">- {{slpDetailsCardData.M300}}</sh-text>
    <sh-tooltip label="{{slpDetailsCardData.M300}}" target="baseSystemCardM300Data" placement='top'
        variation='short'></sh-tooltip>
</div>