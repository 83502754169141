<sh-card style="height: 60vh" label="Overview of subscribed systems">
  <sh-spinner label='Loading Subscibed SLPs' *ngIf="isLoading"></sh-spinner>
  <sh-table *ngIf="!isLoading">
    <sh-table-row slot="header">
      <sh-table-head columns='6' col-m='8' col-s='10'>Business Line</sh-table-head>
      <sh-table-head columns='6' col-m='8' col-s='10'>SLP Name</sh-table-head>
      <sh-table-head columns='4' col-m='4' col-s='2'>Project Manager</sh-table-head>
      <sh-table-head columns='4' col-m='2' col-s='2'>Due Date</sh-table-head>
      <sh-table-head columns='4' col-m='2' col-s='1'>Status</sh-table-head>
    </sh-table-row>

    <sh-table-row button *ngFor="let slp of subscribedSlpData" (click)="navigateToPage(slp)">
      <sh-table-cell columns='6' col-m='8' col-s='10'>{{
        slp["BUISNESSLINE"]
        }}</sh-table-cell>
      <sh-table-cell columns='6' col-m='8' col-s='10'>{{ slp["SLPNAME"] }}</sh-table-cell>
      <sh-table-cell columns='4' col-m='4' col-s='2'>{{
        slp["CSPROJECTMANAGER"]
        }}</sh-table-cell>
      <sh-table-cell columns='4' col-m='2' col-s='2'>{{ slp['DUEDATE'] | date:'yyyy-MM-dd'}}</sh-table-cell>
      <sh-table-cell columns='4' col-m='2' col-s='1'>{{ slp["STATUS"] }}</sh-table-cell>
    </sh-table-row>
  </sh-table>
  <sh-empty-state icon='studies-reject' label='No projects are subscribed'
    *ngIf="!isLoading && subscribedSlpData.length === 0"></sh-empty-state>
</sh-card>