<sh-grid class="monitoringWrapper">
  <app-subscribed-slp [subscribedSlpData]="subscribedSlpData" [isLoading]="isLoading" columns="8"
    class="subscribedwrapper"></app-subscribed-slp>

  <div class="buttonsWrapper" columns="4">
    <div class="functionality-button" [ngClass]="{'disabled': !isNavigationButtonEnabled}" [attr.disabled]="!isNavigationButtonEnabled ? true : null">
      <div class="function-section" columns="6" (click)="navigateToCreateSLPPage()" style="margin-right: 10px;">
        <sh-text size='header-2' class="selection-text">New Project</sh-text>
        <sh-icon icon='plus' size='l' class="selection-icon"></sh-icon>
      </div>

      <div class="function-section" columns="6" (click)="navigateToFGPage()">
        <sh-text class="selection-text" size='header-2'>Create Function Groups</sh-text>
        <sh-icon icon='edit' size='l' class="selection-icon"></sh-icon>
      </div>
    </div>
    <app-state-chart columns="12" class="statusChartWrapper"
      [subscribedSlpStatus]='subscribedSlpStatus'></app-state-chart>
  </div>

  <app-all-slp columns="12" class="allSlpDataCardWrapper" [allSlpData]="allSlpData" [isLoading]="isLoading"
    (updateSubscribedEvents)="updateSubscribedEvents($event)"
    (updateAllSlpProjectAfterDelete)="handlingUpdateAllSlpProjectAfterDelete($event)"
    ></app-all-slp>

</sh-grid>