import { Component, EventEmitter, Output, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SlpProjectData } from 'src/app/models';

@Component({
  selector: 'app-all-slp',
  templateUrl: './all-slp.component.html',
  styleUrls: ['./all-slp.component.scss']
})

export class AllSlpComponent {
  @Input() allSlpData: SlpProjectData[] = [];
  @Input() isLoading = true;
  @Output() updateSubscribedEvents = new EventEmitter<number>();
  @Output() updateAllSlpProjectAfterDelete = new EventEmitter<number>();
  allSLPs: SlpProjectData[] = [];

  constructor(
    private router: Router
  ) { }

  handlingSubscribed(slpID: number) {
    this.updateSubscribedEvents.emit(slpID)
  }

  onViewAllSlpCardClick(selectedSlpData: SlpProjectData) {
    this.router.navigate(['information'], { state: { savedSlpFormData: selectedSlpData } });
  }

  async handlingUpdateAllSlpProjectAfterDelete(slpId: number) {
    this.updateAllSlpProjectAfterDelete.emit(slpId);    
  }

}
